
import { objectConnectionPoints } from '@icepanel/app-graphics'
import { DiagramConnectionPartial, DiagramContentPartial, ModelConnectionRequired, Task } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import Dialog from '@/components/dialog.vue'
import { AlertModule } from '@/modules/alert/store'
import { DiagramModule } from '@/modules/diagram/store'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { TeamModule } from '@/modules/team/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'
import ObjectPreview from '../objects/object-preview.vue'
import ConnectionPreview from './connection-preview.vue'

@Component({
  components: {
    ConnectionPreview,
    Dialog,
    ObjectPreview
  },
  name: 'ModelConnectionFlipDialog'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  teamModule = getModule(TeamModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  loading = false

  connectionId: string | null = null
  diagramHandleId: string | null = null
  originId: string | null = null
  targetId: string | null = null

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentConnection () {
    return this.$queryValue('connection')
  }

  get connectionFlipDialog () {
    return this.$queryValue('connection_flip_dialog')
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentLandscapePermission () {
    return this.landscapeModule.landscapePermission(this.currentLandscape)
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.diagramHandleId)!
  }

  get currentDiagramContent () {
    return Object.values(this.diagramModule.diagramContents).find(o => o.handleId === this.diagramHandleId)!
  }

  get currentDiagramConnection () {
    return Object.values(this.currentDiagramContent.connections).find(o => o.modelId === this.connectionId)
  }

  get connection () {
    return this.connectionId ? this.modelModule.connections[this.connectionId] : null
  }

  get origin () {
    return this.originId ? this.modelModule.objects[this.originId] : null
  }

  get target () {
    return this.targetId ? this.modelModule.objects[this.targetId] : null
  }

  get connectionDiagrams () {
    return Object.values(this.connection?.diagrams || {})
  }

  get connectionFlows () {
    return Object.values(this.connection?.flows || {})
  }

  get isTargetProtected (): boolean {
    if (this.currentLandscapePermission === 'admin') { return false }
    if (!this.connection) { return false }
    const modelObject = this.modelModule.objects[this.connection.targetId]
    return (
      modelObject &&
      modelObject.teamOnlyEditing &&
      !!modelObject.teamIds.length &&
      !this.teamModule.userTeams.some(o => modelObject.teamIds.includes(o.id))
    )
  }

  open () {
    if (this.connectionFlipDialog) {
      this.connectionId = this.connectionFlipDialog
      this.diagramHandleId = this.currentDiagramHandleId
      this.originId = this.connection?.originId || null
      this.targetId = this.connection?.targetId || null
    }
  }

  opened () {
    analytics.modelConnectionFlipDialog.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId]
    })
  }

  closed () {
    this.connectionId = null
    this.diagramHandleId = null
    this.originId = null
    this.targetId = null
  }

  newConnection () {
    const currentDiagramContent = this.currentDiagramContent
    const diagramConnection = this.currentConnection ? currentDiagramContent.connections[this.currentConnection] : undefined
    if (!this.connection || !diagramConnection) {
      return
    }

    const tasks: Task[] = []
    const revertTasks: Task[] = []

    const diagramProps: DiagramContentPartial = {}
    const revertDiagramProps: DiagramContentPartial = {}

    const connectionCreate: ModelConnectionRequired = {
      description: '',
      direction: this.connection.direction,
      name: this.connection.name,
      originId: this.connection.targetId,
      tagIds: [],
      targetId: this.connection.originId
    }
    const { connection, connectionUpsert } = this.modelModule.generateConnection(this.currentLandscape.id, this.currentVersion.id, connectionCreate)
    if (this.currentDiagram.status === 'draft') {
      revertDiagramProps.tasksProposed = {
        ...revertDiagramProps.tasksProposed,
        $append: [
          ...revertDiagramProps.tasksProposed?.$append || [],
          {
            id: connection.id,
            type: 'model-connection-delete'
          }
        ]
      }
      diagramProps.tasksProposed = {
        ...diagramProps.tasksProposed,
        $append: [
          ...diagramProps.tasksProposed?.$append || [],
          {
            id: connection.id,
            props: connectionUpsert,
            type: 'model-connection-create'
          }
        ]
      }
    } else {
      this.modelModule.setConnection(connection)
      this.editorModule.addToTaskQueue({
        func: () => this.modelModule.connectionUpsert({
          connectionId: connection.id,
          landscapeId: this.currentLandscape.id,
          props: connectionUpsert,
          versionId: this.currentVersion.id
        })
      })

      tasks.push({
        id: connection.id,
        props: connectionCreate,
        type: 'model-connection-create'
      })
    }

    const connections = window.structuredClone(currentDiagramContent.connections)

    revertDiagramProps.connections = {
      ...revertDiagramProps.connections,
      $update: {
        ...revertDiagramProps.connections?.$update,
        [diagramConnection.id]: diagramConnection
      }
    }
    const connectionUpdate: DiagramConnectionPartial = {
      modelId: connection.id,
      originConnector: diagramConnection.targetConnector,
      originId: diagramConnection.targetId,
      targetConnector: diagramConnection.originConnector,
      targetId: diagramConnection.originId
    }
    connections[diagramConnection.id] = {
      ...diagramConnection,
      ...connectionUpdate
    }
    diagramProps.connections = {
      ...diagramProps.connections,
      $update: {
        ...diagramProps.connections?.$update,
        [diagramConnection.id]: connectionUpdate
      }
    }

    Object.entries(objectConnectionPoints(Object.values(currentDiagramContent.objects), Object.values(connections))).forEach(([id, points]) => {
      revertDiagramProps.connections = {
        ...revertDiagramProps.connections,
        $update: {
          ...revertDiagramProps.connections?.$update,
          [id]: {
            ...revertDiagramProps.connections?.$update?.[id],
            points: connections[id].points
          }
        }
      }
      connections[id] = {
        ...connections[id],
        points
      }
      diagramProps.connections = {
        ...diagramProps.connections,
        $update: {
          ...diagramProps.connections?.$update,
          [id]: {
            ...diagramProps.connections?.$update?.[id],
            points
          }
        }
      }
    })

    revertTasks.push({
      route: {
        ...this.$route,
        query: {
          ...this.$route.query,
          connection_flip_dialog: null
        }
      },
      type: 'navigation'
    }, {
      id: currentDiagramContent.id,
      props: revertDiagramProps,
      type: 'diagram-content-update'
    })

    if (this.currentDiagram.status !== 'draft') {
      revertTasks.push({
        id: connection.id,
        type: 'model-connection-delete'
      })
    }

    const { diagramContent, diagramContentUpdate, modelConnectionDiagramAdd, modelConnectionDiagramRemove } = this.diagramModule.generateDiagramContentCommit(currentDiagramContent.id, diagramProps)
    this.diagramModule.setDiagramContentVersion(diagramContent)
    this.modelModule.setConnectionDiagrams({ modelConnectionDiagramAdd, modelConnectionDiagramRemove })
    this.editorModule.addToTaskQueue({
      func: () => this.diagramModule.diagramContentUpdate({
        diagramId: currentDiagramContent.id,
        landscapeId: this.currentLandscape.id,
        props: diagramContentUpdate,
        versionId: this.currentVersion.id
      })
    })

    tasks.push({
      id: diagramContent.id,
      props: diagramContentUpdate,
      type: 'diagram-content-update'
    })

    this.editorModule.addTaskList({
      revertTasks,
      tasks: [
        ...tasks,
        {
          route: {
            ...this.$route,
            query: {
              ...this.$route.query,
              connection_flip_dialog: null
            }
          },
          type: 'navigation'
        }
      ]
    })

    analytics.modelConnectionFlipNew.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId]
    })

    this.$replaceQuery({
      connection_flip_dialog: undefined
    })
  }

  async changeModel () {
    if (!this.connection || !this.currentDiagramConnection) {
      return
    }

    try {
      this.loading = true

      if (this.currentDiagram.status === 'draft') {
        const tasks: Task[] = []
        const revertTasks: Task[] = []

        revertTasks.push({
          id: this.currentDiagram.id,
          props: {
            connections: {
              $update: {
                [this.currentDiagramConnection.id]: {
                  originConnector: this.currentDiagramConnection.originConnector,
                  originId: this.currentDiagramConnection.originId,
                  points: this.currentDiagramConnection.points,
                  targetConnector: this.currentDiagramConnection.targetConnector,
                  targetId: this.currentDiagramConnection.targetId
                }
              }
            },
            tasksProposed: {
              $append: [{
                id: this.connection.id,
                props: {
                  originId: this.connection.originId,
                  targetId: this.connection.targetId
                },
                type: 'model-connection-update'
              }]
            }
          },
          type: 'diagram-content-update'
        }, {
          route: {
            ...this.$route,
            query: {
              ...this.$route.query,
              connection_flip_dialog: null
            }
          },
          type: 'navigation'
        })

        const diagramContentPartial: DiagramContentPartial = {
          tasksProposed: {
            $append: [{
              id: this.connection.id,
              props: {
                originId: this.connection.targetId,
                targetId: this.connection.originId
              },
              type: 'model-connection-update'
            }]
          }
        }

        const connections = window.structuredClone(this.currentDiagramContent.connections)

        connections[this.currentDiagramConnection.id].originId = this.currentDiagramConnection.targetId
        connections[this.currentDiagramConnection.id].originConnector = this.currentDiagramConnection.targetConnector
        connections[this.currentDiagramConnection.id].targetId = this.currentDiagramConnection.originId
        connections[this.currentDiagramConnection.id].targetConnector = this.currentDiagramConnection.originConnector

        Object.entries(objectConnectionPoints(Object.values(this.currentDiagramContent.objects), Object.values(connections))).forEach(([id, points]) => {
          if (this.currentDiagramConnection?.id === id) {
            diagramContentPartial.connections = {
              $update: {
                [id]: {
                  originConnector: this.currentDiagramConnection.targetConnector,
                  originId: this.currentDiagramConnection.targetId,
                  points,
                  targetConnector: this.currentDiagramConnection.originConnector,
                  targetId: this.currentDiagramConnection.originId
                }
              }
            }
          }
        })

        const { diagramContent, diagramContentUpdate } = this.diagramModule.generateDiagramContentCommit(this.currentDiagram.id, diagramContentPartial)
        await this.diagramModule.diagramContentUpdate({
          diagramId: diagramContent.id,
          landscapeId: this.currentLandscapeId,
          props: diagramContentUpdate,
          versionId: this.currentVersionId
        })

        tasks.push({
          id: this.currentDiagram.id,
          props: diagramContentUpdate,
          type: 'diagram-content-update'
        }, {
          route: {
            ...this.$route,
            query: {
              ...this.$route.query,
              connection_flip_dialog: null
            }
          },
          type: 'navigation'
        })

        this.editorModule.addTaskList({
          revertTasks,
          tasks
        })
      } else {
        const { connection, connectionUpdate } = this.modelModule.generateConnectionCommit(this.connection.id, {
          originId: this.connection.targetId,
          targetId: this.connection.originId
        })
        await this.modelModule.connectionUpdate({
          connectionId: connection.id,
          landscapeId: this.currentLandscape.id,
          props: connectionUpdate,
          versionId: this.currentVersion.id
        })

        this.editorModule.resetTaskLists()

        if (this.currentDiagram) {
          await this.diagramModule.diagramContentFind({
            diagramId: this.currentDiagram.id,
            landscapeId: this.currentLandscape.id,
            versionId: this.currentVersion.id
          })
        }

        analytics.modelConnectionFlipModel.track(this, {
          landscapeId: [this.currentLandscape.id],
          organizationId: [this.currentLandscape.organizationId]
        })
      }

      await this.$replaceQuery({
        connection_flip_dialog: undefined
      })
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: err.message
      })
    } finally {
      this.loading = false
    }
  }
}
